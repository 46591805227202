import {lazy} from "react"
import {Loadable, MainLayout} from "../components"

const Dashboard = Loadable(lazy(() => import("../pages/dashboard")))
const Chatbots = Loadable(lazy(() => import("../pages/chatbots")))
const ChatbotDetails = Loadable(lazy(() => import("../pages/chatbots/details")))
const ChatbotTemplate = Loadable(lazy(() => import("../pages/chatbots/template")))
const Membership = Loadable(lazy(() => import("../pages/membership")))
const Project = Loadable(lazy(() => import("../pages/project")))
const Error404 = Loadable(lazy(() => import("../pages/auth/404")))
const Clients = Loadable(lazy(() => import("../pages/superAdmin/clients")))
const Prompts = Loadable(lazy(() => import("../pages/superAdmin/prompts")))
const Inbox = Loadable(lazy(() => import("../pages/inbox/inbox")))
const Staff = Loadable(lazy(() => import("../pages/staff/staff")))
const Contentlibrary = Loadable(lazy(() => import("../pages/contentLibrary/contentLibrary")))
const Setting = Loadable(lazy(() => import("../pages/superAdmin/setting")))
const GenericPrompts = Loadable(lazy(() => import("../pages/superAdmin/genericPrompts")))
const Feature = Loadable(lazy(() => import("../pages/superAdmin/setting/feature")))
const Pricing = Loadable(lazy(() => import("../pages/superAdmin/setting/pricing")))
const Billings = Loadable(lazy(() => import("../pages/billings")))
const ClientPricing = Loadable(lazy(() => import("../pages/billings/pricing")))
const Profile = Loadable(lazy(() => import("../pages/profile/index")))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <Dashboard />
        },
        {
            path: "dashboard",
            element: <Dashboard />
        },
        {
            path: "chatbots",
            element: <Chatbots />
        },
        {
            path: "chatbots",
            children: [
                {
                    path: "details",
                    element: <ChatbotDetails />
                },
                {
                    path: "choose-template",
                    element: <ChatbotTemplate />
                }
            ]
        },
        {
            path: "projects",
            element: <Project />
        },
        {
            path: "membership",
            element: <Membership />
        },
        {
            path: "clients",
            element: <Clients />
        },
        {
            path: "prompts",
            element: <Prompts />
        },
        {
            path: "generic-prompts",
            element: <GenericPrompts />
        },
        {
            path: "billing",
            element: <Billings />
        },
        {
            path: "billing",
            children: [
                {
                    path: "packages",
                    element: <ClientPricing />
                }
            ]
        },
        {
            path: "content-library",
            element: <Contentlibrary />
        },
        {
            path: "inbox",
            element: <Inbox />
        },
        {
            path: "staff",
            element: <Staff />
        },
        {
            path: "setting",
            element: <Setting />
        },
        {
            path: "settings",
            children: [
                {
                    path: "feature",
                    element: <Feature />
                },
                {
                    path: "packages",
                    element: <Pricing />
                }
            ]
        },
        {
            path: "profile",
            element: <Profile />
        },
        {
            path: "*",
            element: <Error404 />
        }
    ]
}

export default MainRoutes
